import api from "data/APIs";
import { is_empty } from "utils/validations";
import { composeValidators } from "utils/validations";
import { required, minLength, requiredWithMessage } from "common/validate";

// Communication Events
export const comms = {
  // Comms
  COMM_EMAIL: "Email",
  COMM_WHATSAPP: "WhatsApp",
  COMM_EMAIL_UPDATE: "Update",
  // Comm Schedule
  COMM_CONFIRMATION: "Confirmation",
  COMM_REMINDERS: "Reminders",
  COMM_FOLLOWUPS: "Follow-Ups",
  COMM_IMMEDIATELY: "Immediately",
  COMM_RECORDED_CONTENT_FOLLOWUPS: "recorded_content_follow_ups",
  COMM_CONFIRMATION_PART_PAYMENT_WITH_INITIAL_PAYMENT:
    "part_payment_with_initial_payment_confirmation_comms",
  COMM_CONFIRMATION_PART_PAYMENT_WITH_INSTALMENTS_ONLY:
    "part_payment_with_instalments_only_confirmation_comms",
  COMM_REMINDERS_PART_PAYMENT:
    "part_payment_with_instalments_only_reminders_comms",
  COMM_REMINDERS_SUBSCRIPTION_RENEWAL: "subscription_renewal_reminders_comms",
  // Comm Timing
  COMM_MINUTES: "Minutes",
  COMM_HOURS: "Hours",
  COMM_Days: "Days",
  // EmailVariableBtns
  VARIABLE_PRIMARY: "primary",
  VARIABLE_SECONDARY: "secondary",
  // Actions
  CREATE: "CREATE",
  EDIT: "EDIT",
  DUPLICATE: "DUPLICATE",
  DELETE: "DELETE",
  // Slots Action Params
  ADD_SLOT: 2,
  DELETE_SLOT: 3,
  // SessionStorage
  STORAGE_KEY: "listingCommData",
  // Create Comm Form Keys
  THROUGH: "through",
  TYPE: "type",
  SESSION_BATCHES: "session_batches",
  DURATION: "duration",
  DURATION_TYPE: "duration_type",
  DURATION_FIXED: "duration_fixed",
  ACTION_TYPE: "action_type",
};

export const COMM_SCHEDULE = {
  BEFORE_EVENT: 2,
  AFTER_EVENT: 3,
};

export const COMM_TIMING_CHOICE = {
  MINUTES: 1,
  HOURS: 2,
  DAYS: 3,
};

// front-end keys - start
export const COMMUNICATION_TIMING_BEFORE_EVENT = {
  HOURS_BEFORE: 1,
  DAYS_BEFORE: 3,
  MINUTES_BEFORE: 6,
};
export const COMMUNICATION_TIMING_AFTER_EVENT = {
  HOURS_AFTER: 2,
  DAYS_AFTER: 4,
  MINUTES_AFTER: 5,
};
export const COMMUNICATION_TIMING = {
  ...COMMUNICATION_TIMING_BEFORE_EVENT,
  ...COMMUNICATION_TIMING_AFTER_EVENT,
};

const REMINDERS_DURATION_LIMITS = {
  [COMM_TIMING_CHOICE.MINUTES]: 300,
  [COMM_TIMING_CHOICE.HOURS]: 48,
  [COMM_TIMING_CHOICE.DAYS]: 5,
};
// front-end keys - end

// mappings - start
export const COMM_TIMING_CHOICE_DURATION_TYPE_MAP = {
  [COMMUNICATION_TIMING.MINUTES_BEFORE]: COMM_TIMING_CHOICE.MINUTES,
  [COMMUNICATION_TIMING.MINUTES_AFTER]: COMM_TIMING_CHOICE.MINUTES,
  [COMMUNICATION_TIMING.DAYS_BEFORE]: COMM_TIMING_CHOICE.DAYS,
  [COMMUNICATION_TIMING.DAYS_AFTER]: COMM_TIMING_CHOICE.DAYS,
  [COMMUNICATION_TIMING.HOURS_BEFORE]: COMM_TIMING_CHOICE.HOURS,
  [COMMUNICATION_TIMING.HOURS_AFTER]: COMM_TIMING_CHOICE.HOURS,
};

export const DURATION_TYPE_FROM_TIMING_CHOICE_BEFORE_EVENT = {
  [COMM_TIMING_CHOICE.MINUTES]:
    COMMUNICATION_TIMING_BEFORE_EVENT.MINUTES_BEFORE,
  [COMM_TIMING_CHOICE.DAYS]: COMMUNICATION_TIMING_BEFORE_EVENT.DAYS_BEFORE,
  [COMM_TIMING_CHOICE.HOURS]: COMMUNICATION_TIMING_BEFORE_EVENT.HOURS_BEFORE,
};

export const DURATION_TYPE_FROM_TIMING_CHOICE_AFTER_EVENT = {
  [COMM_TIMING_CHOICE.MINUTES]: COMMUNICATION_TIMING_AFTER_EVENT.MINUTES_AFTER,
  [COMM_TIMING_CHOICE.DAYS]: COMMUNICATION_TIMING_AFTER_EVENT.DAYS_AFTER,
  [COMM_TIMING_CHOICE.HOURS]: COMMUNICATION_TIMING_AFTER_EVENT.HOURS_AFTER,
};

export const COMM_SCHEDULE_TIMING_MAP = {
  [COMMUNICATION_TIMING.HOURS_BEFORE]: COMM_SCHEDULE.BEFORE_EVENT,
  [COMMUNICATION_TIMING.DAYS_BEFORE]: COMM_SCHEDULE.BEFORE_EVENT,
  [COMMUNICATION_TIMING.HOURS_AFTER]: COMM_SCHEDULE.AFTER_EVENT,
  [COMMUNICATION_TIMING.DAYS_AFTER]: COMM_SCHEDULE.AFTER_EVENT,
  [COMMUNICATION_TIMING.MINUTES_AFTER]: COMM_SCHEDULE.AFTER_EVENT,
};
// mappings - end

export const comm_switch = {
  email: {
    label: comms.COMM_EMAIL,
    value: 0,
  },
  whatsapp: {
    label: comms.COMM_WHATSAPP,
    value: 1,
  },
};

export const mapper = {
  type_options: {
    [comms.COMM_EMAIL]: {
      label: comms.COMM_EMAIL,
      value: comms.COMM_EMAIL,
    },
    [comms.COMM_WHATSAPP]: {
      label: comms.COMM_WHATSAPP,
      value: comms.COMM_WHATSAPP,
    },
  },
};

export const constants = {
  tabs: [
    {
      label: "Confirmation",
      value: 1,
    },
    {
      label: "Reminders",
      value: 2,
    },
    {
      label: "Follow-Ups",
      value: 3,
    },
  ],
  default_selected_tabs: 1,
  tab_constants: {
    [comms.COMM_CONFIRMATION]: 1,
    [comms.COMM_REMINDERS]: 2,
    [comms.COMM_FOLLOWUPS]: 3,
    [comms.COMM_IMMEDIATELY]: 4,
  },
  drip_type: {
    1: comms.COMM_CONFIRMATION,
    2: comms.COMM_REMINDERS,
    3: comms.COMM_FOLLOWUPS,
  },
  title_type: {
    1: ["after", "registration"],
    2: ["before", "event"],
    3: ["after", "event"],
    4: ["immediately", ""],
  },
  comm_timing_choice: {
    1: comms.COMM_MINUTES,
    2: comms.COMM_HOURS,
    3: comms.COMM_Days,
  },
  create_comm: {
    title: "Create new communication",
    type: [
      mapper.type_options[comms.COMM_EMAIL],
      mapper.type_options[comms.COMM_WHATSAPP],
    ],

    layout: {
      // [COMM_EMAIL]: {
      comm_through: {
        label: "Communicate Through",
      },
      comm_type: {
        options: (keys) => {
          const options = [
            {
              id: comms.COMM_CONFIRMATION,
              label: comms.COMM_CONFIRMATION,
              value: comms.COMM_CONFIRMATION,
            },
            {
              id: comms.COMM_REMINDERS,
              label: comms.COMM_REMINDERS,
              value: comms.COMM_REMINDERS,
            },
            {
              id: comms.COMM_FOLLOWUPS,
              label: comms.COMM_FOLLOWUPS,
              value: comms.COMM_FOLLOWUPS,
            },
          ];

          if (is_empty(keys)) return options;

          return options.filter((item) => !keys.includes(item.id));
        },
        label: "Communication Type",
        labelTooltip: {
          [comms.COMM_CONFIRMATION]:
            "This is configured from the time of user registration. (For ex. 1 day after the registration)",
          [comms.COMM_REMINDERS]:
            "This is configured from the time of the actual event. (For ex. 2 hours before the workshop)",
          [comms.COMM_FOLLOWUPS]:
            "This is configured from the end time of the event",
        },
        // options: [COMM_CONFIRMATION, COMM_REMINDERS, COMM_FOLLOWUPS],
      },
      comm_time: {
        [comms.COMM_CONFIRMATION]: [
          {
            id: comms.COMM_MINUTES,
            label: `${comms.COMM_MINUTES} After the registration`,
            value: COMMUNICATION_TIMING.MINUTES_AFTER,
          },
          {
            id: comms.COMM_HOURS,
            label: `${comms.COMM_HOURS} After the registration`,
            value: COMMUNICATION_TIMING.HOURS_AFTER,
          },
          {
            id: comms.COMM_Days,
            label: `${comms.COMM_Days} After the registration`,
            value: COMMUNICATION_TIMING.DAYS_AFTER,
          },
        ],
        [comms.COMM_CONFIRMATION_PART_PAYMENT_WITH_INITIAL_PAYMENT]: [
          {
            id: comms.COMM_MINUTES,
            label: `${comms.COMM_MINUTES} after payment`,
            value: COMMUNICATION_TIMING.MINUTES_AFTER,
          },
          {
            id: comms.COMM_HOURS,
            label: `${comms.COMM_HOURS} after payment`,
            value: COMMUNICATION_TIMING.HOURS_AFTER,
          },
          {
            id: comms.COMM_Days,
            label: `${comms.COMM_Days} after payment`,
            value: COMMUNICATION_TIMING.DAYS_AFTER,
          },
        ],
        [comms.COMM_CONFIRMATION_PART_PAYMENT_WITH_INSTALMENTS_ONLY]: [
          {
            id: comms.COMM_MINUTES,
            label: `${comms.COMM_MINUTES} after instalment payment`,
            value: COMMUNICATION_TIMING.MINUTES_AFTER,
          },
          {
            id: comms.COMM_HOURS,
            label: `${comms.COMM_HOURS} after instalment payment`,
            value: COMMUNICATION_TIMING.HOURS_AFTER,
          },
          {
            id: comms.COMM_Days,
            label: `${comms.COMM_Days} after instalment payment`,
            value: COMMUNICATION_TIMING.DAYS_AFTER,
          },
        ],
        [comms.COMM_REMINDERS]: [
          {
            id: comms.COMM_HOURS,
            label: `${comms.COMM_HOURS} Before the session starts`,
            value: COMMUNICATION_TIMING.HOURS_BEFORE,
          },
          {
            id: comms.COMM_Days,
            label: `${comms.COMM_Days} Before the session starts`,
            value: COMMUNICATION_TIMING.DAYS_BEFORE,
          },
          {
            id: comms.COMM_HOURS,
            label: `${comms.COMM_HOURS} after session starts`,
            value: COMMUNICATION_TIMING.HOURS_AFTER,
          },
          {
            id: comms.COMM_Days,
            label: `${comms.COMM_Days} after session starts`,
            value: COMMUNICATION_TIMING.DAYS_AFTER,
          },
        ],
        [comms.COMM_REMINDERS_SUBSCRIPTION_RENEWAL]: [
          {
            id: comms.COMM_HOURS,
            label: `${comms.COMM_MINUTES} before subscription expiry`,
            value: COMMUNICATION_TIMING.MINUTES_AFTER,
          },
          {
            id: comms.COMM_HOURS,
            label: `${comms.COMM_HOURS} before subscription expiry`,
            value: COMMUNICATION_TIMING.HOURS_BEFORE,
          },
          {
            id: comms.COMM_Days,
            label: `${comms.COMM_Days} before subscription expiry`,
            value: COMMUNICATION_TIMING.DAYS_BEFORE,
          },
        ],
        [comms.COMM_REMINDERS_PART_PAYMENT]: [
          {
            id: comms.COMM_HOURS,
            label: `${comms.COMM_HOURS} before instalment payment`,
            value: COMMUNICATION_TIMING.HOURS_BEFORE,
          },
          {
            id: comms.COMM_Days,
            label: `${comms.COMM_Days} before instalment payment`,
            value: COMMUNICATION_TIMING.DAYS_BEFORE,
          },
        ],
        [comms.COMM_FOLLOWUPS]: [
          {
            id: comms.COMM_HOURS,
            label: `${comms.COMM_HOURS} before session ends`,
            value: COMMUNICATION_TIMING.HOURS_BEFORE,
          },
          {
            id: comms.COMM_Days,
            label: `${comms.COMM_Days} before session ends`,
            value: COMMUNICATION_TIMING.DAYS_BEFORE,
          },
          {
            id: comms.COMM_HOURS,
            label: `${comms.COMM_HOURS} After the session ends`,
            value: COMMUNICATION_TIMING.HOURS_AFTER,
          },
          {
            id: comms.COMM_Days,
            label: `${comms.COMM_Days} After the session ends`,
            value: COMMUNICATION_TIMING.DAYS_AFTER,
          },
        ],
        [comms.COMM_RECORDED_CONTENT_FOLLOWUPS]: [
          {
            id: comms.COMM_HOURS,
            label: `${comms.COMM_HOURS} After the session ends`,
            value: COMMUNICATION_TIMING.HOURS_AFTER,
          },
          {
            id: comms.COMM_Days,
            label: `${comms.COMM_Days} After the session ends`,
            value: COMMUNICATION_TIMING.DAYS_AFTER,
          },
        ],
      },
      comm_time_fixed: {
        [comms.COMM_CONFIRMATION]: "Immediately after the registration",
        [comms.COMM_REMINDERS]: "Immediately before the event",
        [comms.COMM_FOLLOWUPS]: "Immediately after the event",
      },
      // },
    },
    INITIAL_FORM_DATA: {
      [comms.THROUGH]: comms.COMM_EMAIL,
      [comms.TYPE]: comms.COMM_CONFIRMATION,
      [comms.SESSION_BATCHES]: [],
      [comms.DURATION]: false,
      [comms.DURATION_TYPE]: COMMUNICATION_TIMING.HOURS_AFTER,
      [comms.DURATION_FIXED]: false,
      [comms.ACTION_TYPE]: comms.CREATE,
    },
  },
  toggleType: {
    switches: [comm_switch.email.label, comm_switch.whatsapp.label],
    selected: comm_switch.email.value,
  },
  variable_btns: [
    { label: "first name", btnColor: comms.VARIABLE_PRIMARY },
    { label: "last name", btnColor: comms.VARIABLE_PRIMARY },
    { label: "phone no.", btnColor: comms.VARIABLE_PRIMARY },
    { label: "country", btnColor: comms.VARIABLE_SECONDARY },
  ],
  apis: {
    [comms.COMM_EMAIL]:
      api.listingSpecificCommunication.listing_comm_email_list,
    [comms.COMM_WHATSAPP]:
      api.listingSpecificCommunication.listing_comm_whatsapp_list,
    [comms.COMM_EMAIL_UPDATE]: api.listingSpecificCommunication.comm_update,
  },
  duration_limit: {
    [COMM_TIMING_CHOICE.MINUTES]: 300,
    [COMM_TIMING_CHOICE.HOURS]: 48,
    [COMM_TIMING_CHOICE.DAYS]: 30,
  },
  duration_err_msg: {
    [COMM_TIMING_CHOICE.MINUTES]:
      "The value of Minutes can not be greater than ",
    [COMM_TIMING_CHOICE.HOURS]: "The value of hours can not be more than ",
    [COMM_TIMING_CHOICE.DAYS]: "The value of days can not be more than ",
  },
  comm_tooltip_title:
    "We have already configured some automated messages that get sent to your users like purchase confirmations & reminders. You can check those under 'Automated Messages' in 'Workflow Tools'. We recommend you to check those out and then configure these communications to avoid a clash between the two",
  fallbackTitles: (type) => {
    switch (type) {
      case comms.COMM_CONFIRMATION:
        return "No Listing Communications Created Yet";
      case comms.COMM_REMINDERS:
        return "No Listing Reminder created yet";
      case comms.COMM_FOLLOWUPS:
        return "No Listing Follow-up created yet";
      default:
        return "No Listing Communications Created Yet";
    }
  },
  drip_types: {
    [comms.COMM_CONFIRMATION]: 1,
    [comms.COMM_REMINDERS]: 2,
    [comms.COMM_FOLLOWUPS]: 3,
  },
  comm_schedule: {
    [comms.COMM_CONFIRMATION]: 1,
    [comms.COMM_REMINDERS]: 2,
    [comms.COMM_FOLLOWUPS]: 3,
    [comms.COMM_IMMEDIATELY]: 4,
  },
  comm_timing: {
    [comms.COMM_MINUTES]: 1,
    [comms.COMM_HOURS]: 2,
    [comms.COMM_Days]: 3,
  },
  duration_min: 1,
  duration_empty_err_msg: `The value cannot be less than `,
};

export const validate = {
  validateDurationTime: composeValidators(required, (value, allValues) => {
    const commTimingChoice =
      COMM_TIMING_CHOICE_DURATION_TYPE_MAP[allValues.duration_type];
    const maxValue = constants.duration_limit[commTimingChoice];
    const minValue = constants.duration_min;

    if (value < minValue) {
      return constants.duration_empty_err_msg + minValue;
    }

    if (value > maxValue) {
      return constants.duration_err_msg[commTimingChoice] + maxValue;
    }
  }),
  // Seggragated Validations for reminders as per requirement
  validateDurationTimeReminders: composeValidators(
    required,
    (value, allValues) => {
      const commTimingChoice =
        COMM_TIMING_CHOICE_DURATION_TYPE_MAP[allValues.duration_type];
      const maxValue = REMINDERS_DURATION_LIMITS[commTimingChoice];
      const minValue = constants.duration_min;

      if (value < minValue) {
        return constants.duration_empty_err_msg + minValue;
      }

      if (value > maxValue) {
        return constants.duration_err_msg[commTimingChoice] + maxValue;
      }
    }
  ),
  validateSessionsBatches: composeValidators(
    required,
    minLength(1, "Sessions / Batches cannot be empty")
  ),
  validateOfferingVariations: composeValidators(
    required,
    minLength(1, "Variations cannot be empty")
  ),
  validateDurationType: composeValidators(
    requiredWithMessage("Communication Type cannot be empty")
  ),
  selectedThrough: (through) => mapper.type_options[through],
};

export const email_subject_label = "Subject";
export const whatsapp_subject_label = "Campaign name";

export const slot_data = "slot_data";
export const slot_uids = "slot_uids";
export const batch_data = "batch_data";
export const batch_uids = "batch_uids";

export const default_page_number = 1;

export const MANAGE_COMMUNICATIONS_LEARN_MORE_HREFS = {
  MANAGE_COMMUNICATIONS:
    "https://support.exlyapp.com/en/support/solutions/articles/84000392255-how-to-set-up-communications-to-the-customers-",
  MANAGE_COMMUNICATIONS_VIDEO:
    "https://www.youtube-nocookie.com/embed/8snUFVK3DA4",
};

export const email_body_html_files_prefix = "lsc";
