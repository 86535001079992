import constants from "constants/constants";
import { getAuthData } from "utils/AuthUtil";

export const getScheduleTypeLabel = (scheduleTypeId) =>
  constants.schedule_type.find((i) => i.id === scheduleTypeId).name;

export const schedule_type_data_map = {
  [constants.scheduleTypesId.workshop]: {
    colors: { text: "#026FC6", background: "#008BF8" },
    label: getScheduleTypeLabel(constants.scheduleTypesId.workshop),
  },
  [constants.scheduleTypesId.one_on_one_class]: {
    colors: { text: "#00C652", background: "#04E762" },
    label: getScheduleTypeLabel(constants.scheduleTypesId.one_on_one_class),
  },
  [constants.scheduleTypesId.group_class]: {
    colors: { text: "#00C652", background: "#04E762" },
    label: getScheduleTypeLabel(constants.scheduleTypesId.group_class),
  },
  [constants.scheduleTypesId.one_on_one_appointment]: {
    colors: { text: "#B69D00", background: "#F5B700" },
    label: getScheduleTypeLabel(
      constants.scheduleTypesId.one_on_one_appointment
    ),
  },
  [constants.scheduleTypesId.webinar]: {
    colors: { text: "#B69D00", background: "#F5B700" },
    label: getScheduleTypeLabel(constants.scheduleTypesId.webinar),
  },
  [constants.scheduleTypesId.rolling_class]: {
    colors: { text: "#D0026E", background: "#DC0073" },
    label: getScheduleTypeLabel(constants.scheduleTypesId.rolling_class),
  },
};

export const getBackgroundColorWOpacity = (color) => color + "1a";

export const address_type_data_map = {
  [constants.address_type_enum.meet]: "Google meet",
  [constants.address_type_enum.joining_link]: "Online Meeting Link",
  [constants.address_type_enum.personal_address]: "At your personal address",
  [constants.address_type_enum.customer_location]: "At customer's address",
  [constants.address_type_enum.zoom]: "Zoom",
  [constants.address_type_enum.phone]: "Phone",
  [constants.address_type_enum.whatsapp]: "Whatsapp",
  [constants.address_type_enum.exly_connect_by_zoom]: "Exly Zoom",
  [constants.address_type_enum.zoom_webinar]: "Zoom Webinar",
};

export const conflicts_default_state = {
  overlapping_sessions: [],
  rescheduled_sessions: [],
  unavailabilities: [],
};

const mapSessionsToConflicts = (item) => ({
  slot_uuid: item.uuid,
  listing_uuid: item.listing_uuid,
  listing_title: item.listing_title,
  type: item.listing_type,
  start_datetime: item.start_datetime,
  end_datetime: item.end_datetime,
  address_type: item.listing_address_type,
});

export const processConflictsInScheduleManagementApiResponse = (conflicts) => {
  const overlapping_sessions = (
    conflicts?.overlapping_sessions ||
    conflicts_default_state.overlapping_sessions
  ).map(mapSessionsToConflicts);

  const rescheduled_sessions = (
    conflicts?.rescheduled_sessions ||
    conflicts_default_state.rescheduled_sessions
  ).map(mapSessionsToConflicts);

  const unavailabilities = (
    conflicts?.unavailabilities || conflicts_default_state.unavailabilities
  ).map((item) => ({
    uuid: item.uuid,
    start_datetime: item.start_datetime,
    end_datetime: item.end_datetime,
    type: item.type,
  }));
  return {
    overlapping_sessions,
    rescheduled_sessions,
    unavailabilities,
  };
};

export const goBack = () => {
  window.history.back();
};

export const confirmAvailabilityRadioGroupOptions = [
  {
    label: "Block Slot for Self",
    value: false,
  },
  {
    label: "Block Slot for entire Team",
    value: true,
  },
];

export const getLocationTitleBasedOnAddressType = (type) => {
  const authData = getAuthData();
  const { personal_address_title } = authData?.custom_headings;
  switch (type) {
    case constants.address_type_enum.meet:
      return "Google meet";
    case constants.address_type_enum.joining_link:
      return "Online Meeting Link";
    case constants.address_type_enum.personal_address:
      return personal_address_title || "At your personal address";
    case constants.address_type_enum.customer_location:
      return "At customer's address";
    case constants.address_type_enum.zoom:
      return "Zoom";
    case constants.address_type_enum.phone:
      return "Phone";
    case constants.address_type_enum.whatsapp:
      return "Whatsapp";
    case constants.address_type_enum.exly_connect_by_zoom:
      return "Exly Zoom";
    case constants.address_type_enum.zoom_webinar:
      return "Zoom Webinar";
  }
};

export const YOUR_SCHEDULE_LEARN_MORE_HREFS = {
  MY_SCHEDULE:
    "https://support.exlyapp.com/en/support/solutions/articles/84000392248-manage-your-availability-on-exly",
  MY_SCHEDULE_VIDEO: "https://www.youtube-nocookie.com/embed/zEhIdPOggNQ",
};
