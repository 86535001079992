import React from "react";
import classnames from "classnames";
import { getColumnConfig } from "./columnConfig/ManageRecordings.columnConfig";
import ExlyTable from "common/Components/ExlyTable";
import { ActionField } from "ui/pages/CourseAnalytics/components/HelperComponents/HelperComponents";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import ViewRecordingModal from "./components/ViewRecordingModal/ViewRecordingModal";
import SaveSectionChangesModal from "./components/SaveSectionChangesModal/SaveSectionChangesModal";
import { InteractiveTooltipContext } from "schedule-v2/components/InteractiveTooltip";
import {
  MANAGE_RECORDING_TOOLTIP_WEIGHTS,
  empty_state_props,
} from "./constants/ManageRecordings.constant";
import { getSingleRecordClassName } from "./utils/ManageRecordings.util";
import { useListController } from "react-admin";
import { is_empty } from "utils/validations";
import SaveChangesButton from "./components/SaveChangesButton/SaveChangesButton";
import ExlyLoader from "ui/modules/ExlyLoader";
import styles from "./ManageRecordings.module.css";
import { SectionHeading } from "features/AdminPanel/modules/SectionHeading/SectionHeading";

const ManageRecordingsTable = (props) => {
  const { tableProps, isOnboarded, flagLoading, recordingProps } = props;
  const {
    listingUuid,
    showViewRecordingModal,
    viewRecordingModalData,
    handleToggleShowViewRecordingModal,
    handleViewRecordingActionClick,
    recordToSelectedSection,
    handleSelectedSectionMappingChange,
    courseSectionOptions,
    setCourseSectionOptions,
    showSectionChangesModal,
    toggleShowSaveSectionChangesModal,
    handleSaveChnagesClick,
    batchesProps,
    sessionsProps,
    customersProps,
    isPageDataLoaing,
    getZoomRcOfferingDetails,
    handleClearMappingStates,
  } = recordingProps;

  const { data: tableData } = useListController(tableProps);

  const { currentActive, setCurrentActive, setAutoPreview } = React.useContext(
    InteractiveTooltipContext
  );

  const isDesktop = useDesktopMediaQuery();

  React.useEffect(() => {
    if (!isOnboarded && !is_empty(tableData)) {
      setCurrentActive(MANAGE_RECORDING_TOOLTIP_WEIGHTS.recording_name);
      setAutoPreview(true);
    }
  }, [isOnboarded, tableData]);

  React.useEffect(() => {
    getZoomRcOfferingDetails();
  }, []);

  const saveChangesCTA = (
    <SaveChangesButton
      currentActive={currentActive}
      isDesktop={isDesktop}
      handleSaveChnagesClick={handleSaveChnagesClick}
    />
  );

  if (isPageDataLoaing || flagLoading) {
    return <ExlyLoader />;
  }

  return (
    <div>
      <div className={styles.body}>
        {isDesktop && <SectionHeading heading="Manage unmapped recordings" />}
        <ExlyTable
          ra_props={{
            ...tableProps,
          }}
          noExportButton
          layoutProps={{
            exlyTableRootClass: "md:tw-py-6 md:tw-mx-12",
            secondaryComponent:
              isDesktop && !is_empty(tableData) && saveChangesCTA,
            paddingBottom: "120px",
          }}
          columnConfig={getColumnConfig(
            handleViewRecordingActionClick,
            recordToSelectedSection,
            handleSelectedSectionMappingChange,
            courseSectionOptions,
            setCourseSectionOptions,
            batchesProps,
            sessionsProps,
            customersProps,
            currentActive,
            isDesktop
          )}
          customPrimaryAction={{
            label: <ActionField label="View recording" />,
            onClick: (record) => {
              handleViewRecordingActionClick(record);
            },
          }}
          borderedFields
          fieldsLeftPadded
          drawerFieldsBordered
          fieldsAlignment="space-between"
          drawerFieldsAlignment="space-between"
          recordFooterVariant="secondary"
          primaryKey="id"
          getRowClassName={(row) =>
            getSingleRecordClassName(row, currentActive)
          }
          getRecordClassName={(row) =>
            getSingleRecordClassName(row, currentActive)
          }
          {...empty_state_props}
        />
      </div>
      {/* Need isDesktop check here, because we want to prevent rendering of the saveChangesCTA 
          even once on dom, else it would also render the tooltip component.*/}
      {!isDesktop && !is_empty(tableData) && (
        <div
          className={classnames(
            styles.save_changes_style,
            currentActive === MANAGE_RECORDING_TOOLTIP_WEIGHTS.save_button &&
              styles.save_changes_tooltip_wrapper
          )}
        >
          {saveChangesCTA}
        </div>
      )}
      {showViewRecordingModal && (
        <ViewRecordingModal
          open={showViewRecordingModal}
          data={viewRecordingModalData}
          handleClose={handleToggleShowViewRecordingModal}
        />
      )}
      {showSectionChangesModal && (
        <SaveSectionChangesModal
          open={showSectionChangesModal}
          handleClose={toggleShowSaveSectionChangesModal}
          listingUuid={listingUuid}
          sectionsData={recordToSelectedSection}
          batchesData={batchesProps.recordToSelectedBatch}
          sessionsData={sessionsProps.recordToSelectedSession}
          customersData={customersProps.recordToSelectedCustomer}
          handleClearMappingStates={handleClearMappingStates}
        />
      )}
    </div>
  );
};

export default ManageRecordingsTable;
