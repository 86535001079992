import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import globalConstants from "constants/constants";

// Tabs
export const LIVEOFFERINGS = "Live Offerings";
export const DRAFTOFFERINGS = "Draft Offerings";
export const PLANS = "Plans";
export const SOLDOUT = "In Waitlist";
export const PASTOFFERINGS = "Past Offerings";

// Options
export const SHARE = "Share";
export const EDIT = "Edit Offering";
export const DUPLICATEOFFERINGS = "Duplicate Offering";
export const MANAGEOFFERING = "Manage Offering";
export const ACCESSCOMMUNITY = "Access Community";
export const MANAGEBOOKINGS = "Manage Bookings";
export const DELETEOFFERINGS = "Delete Offering";
export const STOPSUBSCRIPTION = "Stop Subscription";
export const MANAGECOMMUNICATIONS = "Manage Communications";
export const MANAGEAVAILABILITY = "Manage Availability";
export const POSTBOOKINGURL = "Post Booking URL";
export const RENEWLINK = "Renew Link";
export const FEEDBACKS = "Feedback";
export const CREATESIMILAR = "Create Similar";
export const COMMENTS = "View/Post Comments";
export const DUPLICATEPLAN = "Duplicate Plan";
export const DELETEPLAN = "Delete Plan";
export const EDITPLAN = "Edit Plan";
export const MANAGEPLANBOOKINGS = "Manage Plan Booking";

export const empty_type = null;
export const staff_type = "1";
export const offering_type = "2";

// Enums
export const plan_status_enums = {
  LIVE_PLAN: "LIVE_PLAN",
  DRAFT_PLAN: "DRAFT_PLAN",
  INACTIVE_PLAN: "INACTIVE_PLAN",
};

export const plan_status_labels = {
  [plan_status_enums.LIVE_PLAN]: "Active",
  [plan_status_enums.DRAFT_PLAN]: "Draft",
  [plan_status_enums.INACTIVE_PLAN]: "Expired",
};

export const planStatusLabelMapping = {
  1: plan_status_labels[plan_status_enums.LIVE_PLAN],
  2: plan_status_labels[plan_status_enums.INACTIVE_PLAN],
  3: plan_status_labels[plan_status_enums.DRAFT_PLAN],
};

export const constants = {
  tabLabels: [LIVEOFFERINGS, DRAFTOFFERINGS, SOLDOUT, PASTOFFERINGS, PLANS],
  layout_live: {
    shareAction: [SHARE],
    primaryAction: [MANAGEOFFERING],
    action: [MANAGEBOOKINGS],
    more: [
      EDIT,
      DUPLICATEOFFERINGS,
      ACCESSCOMMUNITY,
      COMMENTS,
      RENEWLINK,
      STOPSUBSCRIPTION,
      DELETEOFFERINGS,
    ],
    enableFilter: true,
    cardClick: true,
  },
  layout_draft: {
    primaryAction: [DELETEOFFERINGS],
    action: [EDIT],
    enableFilter: true,
    cardClick: true,
  },
  layout_past: {
    primaryAction: [MANAGEOFFERING],
    action: [CREATESIMILAR],
    enableFilter: true,
  },
  layout_soldout: {
    shareAction: [SHARE],
    primaryAction: [MANAGEOFFERING],
    action: [MANAGEBOOKINGS],
    more: [EDIT, DUPLICATEOFFERINGS, ACCESSCOMMUNITY, DELETEOFFERINGS],
    enableFilter: true,
    cardClick: true,
  },
  layout_plan: {
    shareAction: [SHARE],
    primaryAction: [EDITPLAN],
    action: [MANAGEPLANBOOKINGS],
    more: [DUPLICATEPLAN, DELETEPLAN],
    enableFilter: false,
    cardClick: true,
  },
  layout_draft_plan: {
    action: [EDITPLAN],
    more: [DELETEPLAN],
    enableFilter: false,
    cardClick: true,
  },
  layout_inactive_plan: {
    action: [SHARE],
    more: [DELETEPLAN],
    enableFilter: false,
    cardClick: false,
  },
  permissions: () => {
    return {
      sharePermision: orgPermissions.canShare(),
      canEdit: !orgPermissions.isTabHidden(
        RESOURCE_KEYS.SECTIONS.CREATE_EDIT_LISTINGS,
        RESOURCE_KEYS.SUBSECTIONS.CREATE_EDIT_LISTINGS.CREATE_EDIT_LISTINGS,
        RESOURCE_KEYS.TABS.CREATE_EDIT_LISTINGS.CREATE_EDIT_LISTINGS
          .MANAGE_APPOINTMENT
      ),
      canEditDuplicates: orgPermissions.canCreate(),
      canEditAvailability: orgPermissions.canEdit(),
      canEditPostBooking: orgPermissions.canEdit(),
      canDeleteRecord: orgPermissions.canDelete(),
      canFeedback: true,
    };
  },
  skuList: globalConstants.schedule_type.map((item) => ({
    id: item.id,
    label: item.name,
    value: item.id,
  })),
  filterType: [
    { id: "1", label: "Staff", value: "1" },
    { id: "2", label: "Offering Type", value: "2" },
  ],
  lazyLoad: {
    desktop: {
      bottom: "0px",
      loadItems: 12,
    },
    mobile: {
      bottom: "100px",
      loadItems: 6,
    },
  },
  fallbackTitle: {
    live: "No Offerings yet",
    draft: "You have no drafts saved currently. Start drafting one.",
    plans: "No Plans Created Yet",
    soldOut: "No Waitlist offerings yet",
    past: "No Offerings yet",
    plan_titleSecondary:
      "As soon as we have a new plan, it will show up here. View the video to learn more.",
  },
  buttonText: {
    plans: "Create Plan",
  },
  tabTag: {
    Plans: "New",
  },
  tabToolTip: {
    Plans:
      "Displays group of offerings as a drop down menu on the listing page.",
  },
  tabLabel: {
    live: 1,
    draft: 2,
    sold: 3,
    past: 4,
    plan: 5,
  },
  minPlanTitleLen: 3,
  postBookingUrlBtnMax: 35,
  offeringDescriptionOptions: [
    {
      label: `Save changes only for the selected offering`,
      value: 0,
    },
    {
      label: `Copy the same description for all offerings selected and save`,
      value: 1,
    },
  ],
  waitListInfoText:
    "When the offering is marked as “In Waitlist”, the visitors of your website will see an option to join a waitlist. This can also be used to mark an offering as sold out.",
};

export const MY_OFFERINGS_LEARN_MORE_HREFS = {
  CREATE_EDIT_OFFERINGS:
    "https://support.exlyapp.com/support/solutions/84000147934",
  CREATE_PLANS:
    "https://support.exlyapp.com/support/solutions/articles/84000392225-how-to-bundle-offerings-in-plans-on-exly",
  MY_OFFERINGS_VIDEO: "https://www.youtube-nocookie.com/embed/oi301eoa-l0",
  PLANS_VIDEO: "https://www.youtube-nocookie.com/embed/6jfgHBUcgz4",
};
